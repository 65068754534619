.cta-box {
    border: 2px solid color-border(button-border);
    border-radius: 5px;
    background-color: color-bg(body);
    padding: 5px;
    display:inline-block;
    color: color(high-contrast-inverse);

    &.cta-box-outer {
        border-color: color-border(high-contrast-inverse);
        background-color: color-bg(body);
    }
    &.cta-box-inner {
        border-color: color-border(divider-inverse);
        background-color: color-bg(body);
    }
}
